
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { TagTypeList } from '@/collections/tag_types';
import { TemplateReportModel } from '@/models/template_report';
import { TemplateReportList } from '@/collections/template_reports';
import { DashboardList } from '@/collections/dashboards';
import { StoreModel } from '@/models/store';
import { DashboardModel } from '@/models/dashboard';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { RuleModel } from '@/models/rule';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import SidebarMenu from '../components/SidebarMenu.vue';

@Component({
  components: {
    FeatureLockedNotify,
    SidebarMenu,
  },
})
export default class HorizontalMenu extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new TagTypeList() })
  public tagTypes!: TagTypeList;
  public dashboards: DashboardList = new DashboardList();
  public publicDashboards: DashboardList = new DashboardList();
  public loading: boolean = false;
  public reportSearchResults: TemplateReportModel[] = [];
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  public search: string = '';
  public searchDialog: boolean = false;
  public maxSearchResult: number = 8;
  public realShopifyName: string = '';
  public shopifyStoreBackendUrl: string = '';
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isEmbeddedMode!: boolean;
  public dialogAddDashboard: boolean = false;
  public newDashboardName: string = '';
  public newDashboardError: boolean = false;
  public lockDashboardRule: RuleModel = new RuleModel({ code: 'add_report_dashboard' });
  public isLoadingDashboardList: boolean = false;
  public menuItems: any[] = [
    {
      title: 'My Reports',
      path: '/my-reports',
      target: '',
    },
    {
      title: 'Schedule',
      path: '/schedule',
      target: '',
    },
    {
      title: 'User Management',
      path: '/users',
      target: '',
    },
    {
      title: 'Subscription',
      path: '/subscription',
      target: '',
    },
    {
      title: 'Setting',
      path: '/settings',
      target: '',
    },

  ];

  public profileMenu: any[] = [
    {
      title: 'User Management',
      path: '/users',
    },
    {
      title: 'Settings',
      path: '/settings',
    },
    {
      title: 'Subscription',
      path: '/subscription',

    },
  ];

  public async created() {
    this.realShopifyName = localStorage.getItem('shopify_store') || '';
    this.shopifyStoreBackendUrl = 'https://' + this.realShopifyName + '/admin';
  }

  public getTranslatedText(text: string, module: string) {
    if (this.$te(`${module}.${text}`)) {
      return this.$t(`${module}.${text}`);
    } else {
      return text;
    }
  }



  public doLogout() {
    this.$emit('logout');
  }
  public getTranslatedReportName(reportName: string) {
    if (this.$te(`report_name.${reportName}`)) {
      return this.$t(`report_name.${reportName}`);
    } else {
      return reportName;
    }
  }

  public get storeContactName() {
    let contactName: string = this.realShopifyName;
    if (this.store.config && this.store.config.contactName) {
      contactName = this.store.config.contactName;
    }
    return contactName;
  }
  public get searchResultNumber() {
    if (this.reportSearchResults.length === 1) {
      return this.$t('menu.Show') + ' ' +
        this.reportSearchResults.length + ' / ' +
        this.reportSearchResults.length +
        this.$t('menu.found report');
    }
    if ((this.reportSearchResults.length > 0) && (this.reportSearchResults.length <= this.maxSearchResult)) {
      return this.$t('menu.Show') + ' ' +
        this.reportSearchResults.length + ' / ' +
        this.reportSearchResults.length + ' ' +
        this.$t('menu.found reports');
    }
    if ((this.reportSearchResults.length > 0) && (this.reportSearchResults.length > this.maxSearchResult)) {
      return this.$t('menu.Show') + ' ' +
        this.maxSearchResult + ' / ' +
        this.reportSearchResults.length + ' ' +
        this.$t('menu.found reports');
    }
    return this.$t('menu.Search Results');
  }

  public viewReport(report: TemplateReportModel) {
    this.searchDialog = false;
    this.$router.push(this.getReportLink(report));
  }

  public getReportLink(report: TemplateReportModel) {
    return (`/reports/${report.id}`);
  }

  public isMatchSearch(reportName: string) {
    if (this.search !== '') {
      const searchKeywords = this.search.toLocaleLowerCase().split(' ');
      const itemName = reportName.toLowerCase();
      for (const keyword of searchKeywords) {
        if (!keyword) {
          continue;
        }
        if (!itemName.match(keyword)) {
          return false;
        }
      }
    }
    return true;
  }

  public searchReport() {
    this.$router.push('/reports/3/sales-performance?keyword=' + this.search);
    this.search = '';
  }

  public isLastItem(index: number) {
    if (this.reportSearchResults.length > this.maxSearchResult) {
      if (index >= this.maxSearchResult - 1) {
        return true;
      }
    } else {
      if (index === this.reportSearchResults.length - 1) {
        return true;
      }
    }
    return false;
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/reports');
    }
  }

  public isPaidMerchant() {
    if (this.store.currentPackage) {
      if (this.store.currentPackage.name === 'Free') {
        return false;
      }
    }
    return true;
  }

  @Watch('templateReports', { immediate: true, deep: true })
  private onTemplateReportsChanged(newVal: any) {
    if (this.templateReports.items.length > 0) {
      this.reportSearchResults = this.templateReports.items;
    }
  }
}
